// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import MemberDirectory2 from "../../blocks/MemberDirectory2/src/MemberDirectory2";
import DigitalSignatures from "../../blocks/DigitalSignatures/src/DigitalSignatures";
import SubscriptionBilling from "../../blocks/SubscriptionBilling/src/SubscriptionBilling";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import NavigationMenu from "../../blocks/NavigationMenu/src/NavigationMenu";
import ReceiptCustomisation from "../../blocks/ReceiptCustomisation/src/ReceiptCustomisation";
import AutomaticReminders from "../../blocks/AutomaticReminders/src/AutomaticReminders";
import AutomaticRenewals from "../../blocks/automaticrenewals/src/AutomaticRenewals";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import CfClientFacingSurvey7 from "../../blocks/CfClientFacingSurvey7/src/CfClientFacingSurvey7";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import SingleSignonsso from "../../blocks/SingleSignonsso/src/SingleSignonsso";
import CfCpaChargeApi4 from "../../blocks/CfCpaChargeApi4/src/CfCpaChargeApi4";
import DragDropInterface from "../../blocks/DragDropInterface/src/DragDropInterface";
import Payments from "../../blocks/Payments/src/Payments";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import ElasticSearch from "../../blocks/ElasticSearch/src/ElasticSearch";
import QuestionBank from "../../blocks/QuestionBank/src/QuestionBank";
import Analytics from "../../blocks/analytics/src/Analytics";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import LeadManagement from "../../blocks/LeadManagement/src/LeadManagement";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";



const routeMap = {
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
MemberDirectory2:{
 component:MemberDirectory2,
path:"/MemberDirectory2"},
DigitalSignatures:{
 component:DigitalSignatures,
path:"/DigitalSignatures"},
SubscriptionBilling:{
 component:SubscriptionBilling,
path:"/SubscriptionBilling"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
ReceiptCustomisation:{
 component:ReceiptCustomisation,
path:"/ReceiptCustomisation"},
AutomaticReminders:{
 component:AutomaticReminders,
path:"/AutomaticReminders"},
AutomaticRenewals:{
 component:AutomaticRenewals,
path:"/AutomaticRenewals"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
CfClientFacingSurvey7:{
 component:CfClientFacingSurvey7,
path:"/CfClientFacingSurvey7"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
SingleSignonsso:{
 component:SingleSignonsso,
path:"/SingleSignonsso"},
CfCpaChargeApi4:{
 component:CfCpaChargeApi4,
path:"/CfCpaChargeApi4"},
DragDropInterface:{
 component:DragDropInterface,
path:"/DragDropInterface"},
Payments:{
 component:Payments,
path:"/Payments"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
ElasticSearch:{
 component:ElasticSearch,
path:"/ElasticSearch"},
QuestionBank:{
 component:QuestionBank,
path:"/QuestionBank"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
LeadManagement:{
 component:LeadManagement,
path:"/LeadManagement"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;